import * as React from "react"

import App from './App'

import DefaultApp from  '../../images/phone.png'
import Fit15 from '../../images/apps/nitor-fit-15/preview.png'
import LiftLog from '../../images/apps/nitor-lift-log/preview.png'

const TopApps = () => {
  const [activeApp, setActiveApp] = React.useState(1);

  return (
    <div className="Top-apps">
      <div className="maxWidth flex-row">
        <div className="col">
          <h2 className="h1">Our Top Apps</h2>
          <App active={activeApp === 1} onClick={() => setActiveApp(1)} name="Nitor Fit 15" desc="Quick workouts for all skill levels" image="/images/apps/nitor-fit-15/icon.png" />
          <App active={activeApp === 2} onClick={() => setActiveApp(2)} name="Nitor Lift Log" desc="Easily record and track progress at the gym" image="/images/apps/nitor-lift-log/icon.png" />
        </div>
        <div className="col Top-apps__image">
          { activeApp === 1
            ? <img src={Fit15} width=" 75%" />
            : <img src={LiftLog} width=" 75%" />}
        </div>
      </div>
    </div>
  )
}


export default TopApps
