import * as React from "react"


const Card = ({title, text, link}) => {

  return (
    <div className="Card">
      <h3>{ title }</h3>
      <p>{ text }</p>
      <div className="Card__linkWrapper">
        <a href={link}>Explore More</a>
      </div>
    </div>
  )
}


export default Card
