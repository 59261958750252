import * as React from "react"


const App = ({active, onClick, name, desc, image}) => {

  return (
    <div className={`App flex-row ${active ? "active" : null}`} onClick={onClick}>
      <div>
        <img src={image} width="69px" />
      </div>
      <div className="App__content">
        <h3>{ name }</h3>
        { active
          ? <p>{desc}</p>
          : null
        }
      </div>
    </div>
  )
}


export default App
