import * as React from "react"
import { Link, useStaticQuery, graphql  } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Summary from "../components/home/Summary"
import TopApps from "../components/home/TopApps"

import HeaderImage from "../images/main.webp"
import RunningImage from "../images/running.webp"

const IndexPage = () => {
  const data = useStaticQuery(query);
  console.log(data)
  return (
    <Layout>
      <Seo title="Home" />
      <div className="Home">

        <div className="Hero">
          <div className="Hero__content">
            <h1>NITOR FITNESS</h1>
            <h1>
              <span className="accentColor">(NOUN)</span>
              <span>" - Support Oneself / Trust In"</span>
            </h1>
            <p>Nitor Fitness is a fitness focused software company. Our integrated suite of apps looks to change how you think about fitness and provide a starting point for everyone.</p>
          </div>
          <div className="Hero__image">
            <img src={HeaderImage} width="100%" />
          </div>
        </div>
        <div className="Home__about section">
          <div className="maxWidth flex-row">
            <div className="col">
              <h2 className="h1">Who Are We?</h2>
              <p className="large">Nitor Fitness is a company dedicated to making fitness easy and accesible to everyone. We create specialized fitness apps that appeal to a wide range of users at all levels of fitness and commitment.</p>
            </div>
            <div className="col padding-mobile">
              <img src={RunningImage} width="100%" />
            </div>
          </div>
        </div>
        <TopApps />
        <Summary />
      </div>
    </Layout>
  )
}

const query = graphql`
query {
  allStrapiArticle {
    edges {
      node {
        strapiId
        title
        content
      }
    }
  }
}
`;

export default IndexPage
