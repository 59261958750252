import * as React from "react"
import Card from "../Card"

import BgImage from "../../images/bg.jpg"

const Summary = () => {

  return (
    <div className="Summary section">
      <div className="maxWidth flex-row">
        <div className="col">
          <h2>Our Goals</h2>
          <p className="large">The team at Nitor Fitness is excited to always be focused on bringing you the best in fitness content. We're hard at work building software, as well as regularly updating our blog with the best in fitness related content.</p>
        </div>
        <div className="col padding-mobile Summary__image" style={{backgroundImage:  `url(${BgImage})`}}>
        </div>
        <div className="col padding-mobile">
          <Card title="Explore Our Apps" text="View and explore our currently selection of fitness focused apps." link="/apps"/>
          <Card title="Learn About Fitness" text="We're constantly updting our blog with the new workouts and fitness related content." link="/blog"/>
        </div>
      </div>
    </div>
  )
}


export default Summary
